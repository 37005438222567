import React, { Component, useState, useEffect, useContext } from 'react'
import jsforce from 'jsforce'
import Grid from '@material-ui/core/Grid/Grid'
import { withRouter } from 'react-router-dom'
import {
  CssBaseline,
  AppBar,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
  Button,
  TextField,
  CircularProgress,
  Fab
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon';
import ArrowUpward from '@material-ui/icons/ArrowUpward'
import Search from '@material-ui/icons/Search'
import ProgramCard from '../components/ProgramCard'
import InfiniteScroll from 'react-infinite-scroller';
import SfApi from '../components/SfApi'
import ScrollToTop from 'react-scroll-up'
import AuthApi from '../../auth/AuthApi'
import { FiltersContainer } from './FiltersContainer';
import { FilterContext } from '../../contexts/FilterContext';
import { AuthenticatedNav } from '../components/AuthenticatedNav';

const drawerWidth = 500;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  programGrid: {
    marginTop: '64px',
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  container: {
    padding: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
  },
  loadingSpinner: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  noSearchResults: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  noSearchResultsIcon: {
    fontSize: '3rem',
    color: '#807f83',
    marginBottom: '10px',
  },
  noSearchResultsText: {
    fontSize: '1rem',
    color: '#807f83',
  },
  filterContainer: {
    marginBottom: '1rem',
    width: '100%',
  },
  programs: {
    minWidth: '500px'
  }
}));

export function ProgramsRoot(props) {
  const programLimit = 25
  const [programs, setPrograms] = useState([])
  const filterContext = useContext(FilterContext)
  const [infiniteScrollKey, setInfiniteScrollKey] = useState(Date.now())
  const [pageStart, setPageStart] = useState(0)
  const [tokenRefreshed, setTokenRefreshed] = useState(false)
  const [connConfig, setConnConfig] = useState()
  const [totalPrograms, setTotalPrograms] = useState(0)
  const [fetchPrograms, setFetchPrograms] = useState(true)
  const { container, history } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    if(filterContext.initialFiltersLoaded) {
      SfApi.fetchToken().then((config) => {
        setConnConfig(config)
        makeSfRequest(new jsforce.Connection(config), 0)
      })
    }
  }, [filterContext.initialFiltersLoaded]) // this will only run once url params are parsed

  useEffect(() => {
    if(connConfig && filterContext.filterValuesFetched) {
      console.log('connConfig: ', connConfig)
      makeSfRequest(new jsforce.Connection(connConfig), 0)
    }
  }, [connConfig, filterContext.filterValuesFetched]) // this will only run once filter values from SF have all returned

  function makeSfRequest(conn, page) {
    var callback = (err, records) => {
      if(err) {
        !tokenRefreshed && SfApi.fetchToken().then((config) => {
          setConnConfig(config)
          setTokenRefreshed(true)
        })
      }

      if(page) {
        setPrograms([...programs, ...records ])
      } else {
        window.scrollTo(0, 0)

        setPrograms([...records])
      }

      setTotalPrograms(records.length)
      setFetchPrograms(records.length === programLimit)
    }
    if(filterContext.filters.minSalary || filterContext.filters.maxSalary) {
      SfApi.getPrograms({
        minSalary: filterContext.filters.minSalary,
        maxSalary: filterContext.filters.maxSalary,
      }).then(({ programs }) => {
        if (programs.length === 0) {
          callback(null, [])
        } else {
          const remoteIds = programs.map((program) => program.remoteId);
          SfApi.getPostSecondaryPrograms({conn, page, programLimit, filters: filterContext.filters, remoteIds, callback, filterListValues: filterContext.filterListValues })
        }
      })
    } else {
      const { minSalary, maxSalary, ...externalFilters } = filterContext.filters;
      SfApi.getPostSecondaryPrograms({conn, page, programLimit, filters: externalFilters, callback, filterListValues: filterContext.filterListValues });
    }
  }

  function getPostSecondaryPrograms(page) {
    makeSfRequest(new jsforce.Connection(connConfig), page)
  }

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  function handleSubmit() {
    let queries = filterContext.currentQueryParams()

    history.push(`?${queries}`)
    // force infinite scroll to reset from a new 'key' prop and increment start page by 1 to start on second page
    setInfiniteScrollKey(Date.now())
    setPageStart(1)
    setFetchPrograms(false)

    // explicitly query salesforce rather than relying on a state var to control the fetch
    getPostSecondaryPrograms(0);
  }

  function handleSignOut() {
    new AuthApi().signOut().then(() => {
      window.location =  "/sign_in";
    });
  };

  const programCards = () => {
    if (filterContext.filters.search !== '' && programs && programs.length === 0 && !fetchPrograms) {
      return (
        <div className={classes.noSearchResults} data-role="no-search-results">
          <Search className={classes.noSearchResultsIcon} />
          <div className={classes.noSearchResultsText} >
            We couldn't find any programs that matched your search. Try removing some of your filters, or clear them all and start again.
          </div>
        </div>
      )
    } else if (programs) {
      return (
        <InfiniteScroll
          pageStart={pageStart}
          key={infiniteScrollKey}
          initialLoad={false}
          threshold={400}
          loadMore={fetchPrograms ? getPostSecondaryPrograms : () => {} }
          hasMore={fetchPrograms}
          loader={<div className={classes.loadingSpinner} key={infiniteScrollKey}> <CircularProgress/></div>}>
          <div className={classes.programs} data-role="programs-container">
            {programs.map(program => (
              <ProgramCard program={program} key={program.Id} />
            ))}
            <ScrollToTop showUnder={500}>
              <Fab color="primary" aria-label="Add" className={classes.fab} data-role="scroll-to-top" >
                <ArrowUpward />
              </Fab>
            </ScrollToTop>
          </div>
        </InfiniteScroll>
      )
    }
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <nav className={classes.drawer}>
        <div>
          {/*<Hidden smUp implementation="css">*/}
            {/*<Drawer*/}
              {/*container={container}*/}
              {/*variant="temporary"*/}
              {/*anchor={theme.direction === 'rtl' ? 'right' : 'left'}*/}
              {/*open={mobileOpen}*/}
              {/*onClose={handleDrawerToggle}*/}
              {/*classes={{*/}
                {/*paper: classes.drawerPaper,*/}
              {/*}}*/}
              {/*ModalProps={{*/}
                {/*keepMounted: true,*/}
              {/*}}*/}
            {/*>*/}
              {/*<FiltersContainer connConfig={connConfig}/>*/}
            {/*</Drawer>*/}
          {/*</Hidden>*/}
          <Hidden implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              <FiltersContainer connConfig={connConfig} handleSubmit={handleSubmit}/>
            </Drawer>
          </Hidden>
        </div>
      </nav>
      <AuthenticatedNav />
      <main className={classes.content}>
        <Grid container justify="center" className={classes.programGrid}>
          <Grid item xs={12} lg={12}>
            {programCards()}
          </Grid>
        </Grid>
      </main>
    </div>
  );
}

export default withRouter(ProgramsRoot)
