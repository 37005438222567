import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => ({
  errorText: {
    backgroundColor: theme.palette.error.dark,
  },
  closeIcon: {
    fontSize: 20,
  },
  icon: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    minWidth: '385px'
  }
}));

export function ErrorBanner({ message, dataRole, clearError, open }) {
  const classes = useStyles();

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={6000}
      onClose={() => {}}
      data-role={dataRole}
      className={classes.container}
    >
      <SnackbarContent
        className={classes.errorText}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <ErrorIcon className={classes.icon} />
            { message }
          </span>
        }
        autoHideDuration={6000}
        action={[
          <IconButton key="close" aria-label="close" color="inherit" onClick={clearError}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  )
}

export default ErrorBanner;
