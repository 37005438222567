import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import UserApi from '../../auth/UserApi'
import ErrorBanner from './ErrorBanner';
import { AuthContext } from '../../contexts/AuthContext';
import LogoBanner from './LogoBanner';
import Cookies from 'js-cookie'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Nav } from '../components/Nav';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(20),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  avatar: {
    margin: theme.spacing(),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  input: {
    marginBottom: '1.3rem',
  },
  nameFields: {
    display: 'flex',
    marginBottom: '1.3rem',
  },
  leftInput: {
    marginRight: '1rem'
  },
  formControl: {
    paddingBottom: theme.spacing(2),
    minWidth: '100%',
  },
})

export function SignUp({ classes, onSubmit, error, history }) {
  const authContext = useContext(AuthContext)
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [email, setEmail] = useState()
  const [title, setTitle] = useState()
  const [phone, setPhone] = useState()
  const [schools, setSchools] = useState()
  const [organizationAffiliation, setOrganizationAffiliation] = useState()
  const [studentsServed, setStudentsServed] = useState()
  const [gradesServed, setGradesServed] = useState()
  const [password, setPassword] = useState()
  const [passwordConfirmation, setPasswordConfirmation] = useState()
  const [signUpError, setSignUpError] = useState()
  const [type, setType] = useState('')

  const handleChange = (event) => {
    setType(event.target.value)
    setPhone(undefined)
    setTitle(undefined)
    setStudentsServed(undefined)
    setPassword(undefined)
    setGradesServed(undefined)
  };


  const submit = (e) => {
    e.preventDefault()
    new UserApi().createUser({
      type,
      firstName,
      lastName,
      email,
      title,
      phone,
      organizationAffiliation,
      schools,
      studentsServed,
      gradesServed,
      password,
      passwordConfirmation,
    }).then((response) => {
      let data = response.data
      authContext.setIsAuthenticated(true)
      Cookies.set('user', JSON.stringify(data.user))
      authContext.setCurrentUser(data.user)
      history.push('/')
    }).catch(() => {
      setSignUpError('Code not valid or user already exists')
    })
  }

  const clearError = () => {
    setSignUpError(null)
  }

  const resolveFormFieldsForUserType = () => {
    if (type === "Staff") {
      return (
        <>
          <div className={classes.nameFields}>
            <Input
              name="first-name"
              label="First Name"
              className={classes.leftInput}
              fullWidth
              margin="normal"
              placeholder="First Name *"
              onChange={(e) => setFirstName(e.target.value)}
              autoFocus
              required
            />
            <Input
              name="last-name"
              label="Last Name"
              fullWidth
              margin="normal"
              placeholder="Last Name *"
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </div>
        <Input
          name="email"
          label="Email"
          className={classes.input}
          fullWidth
          margin="normal"
          placeholder="Email *"
          onChange={(e) => setEmail(e.target.value)}
          autoComplete="email"
          error={error}
          required
        />
        <Input
          name="title"
          label="Title"
          className={classes.input}
          fullWidth
          margin="normal"
          placeholder="Title *"
          onChange={(e) => setTitle(e.target.value)}
          autoComplete="title"
          required
        />
        <Input
          name="phone"
          label="Phone"
          type="tel"
          className={classes.input}
          fullWidth
          margin="normal"
          placeholder="Phone *"
          onChange={(e) => setPhone(e.target.value)}
          autoComplete="phone"
          required
        />
        <Input
          name="organization_affiliation"
          label="Organization"
          className={classes.input}
          fullWidth
          margin="normal"
          placeholder="Organization"
          onChange={(e) => setOrganizationAffiliation(e.target.value)}
          autoComplete="organization_affiliation"
        />
        <Input
          name="schools"
          label="School(s)"
          className={classes.input}
          fullWidth
          margin="normal"
          placeholder="School(s)"
          onChange={(e) => setSchools(e.target.value)}
          autoComplete="schools"
        />
        <Input
          name="students-served"
          label="# of Students Served"
          className={classes.input}
          fullWidth
          margin="normal"
          type="number"
          placeholder="# of Students Served"
          onChange={(e) => setStudentsServed(e.target.value)}
        />
        <Input
          name="grades-served"
          label="Grades Served"
          className={classes.input}
          fullWidth
          margin="normal"
          placeholder="Grades Served"
          onChange={(e) => setGradesServed(e.target.value)}
        />
        <Input
          name="code"
          type="text"
          label="Code"
          className={classes.input}
          fullWidth
          onChange={(e) => {
            clearError()
            setPassword(e.target.value)
          }}
          required
          error={error}
          margin="normal"
          placeholder="Code"
        />
      </>
      )
    } else if (type === "Student") {
      return (
        <>
          <div className={classes.nameFields}>
            <Input
              name="first-name"
              label="First Name"
              className={classes.leftInput}
              fullWidth
              margin="normal"
              placeholder="First Name *"
              onChange={(e) => setFirstName(e.target.value)}
              autoFocus
              required
            />
            <Input
              name="last-name"
              label="Last Name"
              fullWidth
              margin="normal"
              placeholder="Last Name *"
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </div>
          <Input
            name="email"
            label="Email"
            className={classes.input}
            fullWidth
            margin="normal"
            placeholder="Email *"
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="email"
            error={error}
            required
          />
          <Input
            name="schools"
            label="School"
            className={classes.input}
            fullWidth
            margin="normal"
            required
            placeholder="Name of Your School or Organization *"
            onChange={(e) => setSchools(e.target.value)}
            autoComplete="schools"
          />
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Grade in School or Class in College *</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={gradesServed}
              required
              onChange={(e) => setGradesServed(e.target.value)}
            >
              {Array(16).fill(0).map((_, i) => {
                if (i + 1 >= 9) {
                  return <MenuItem value={i + 1}>{i + 1}</MenuItem>
                }
              })}
            </Select>
          </FormControl>
          <Input
            name="code"
            type="text"
            label="Code"
            className={classes.input}
            fullWidth
            onChange={(e) => {
              clearError()
              setPassword(e.target.value)
            }}
            required
            error={error}
            margin="normal"
            placeholder="Code *"
          />
        </>
      )
    } else {
      return null
    }
  }

  return (
    <div>
      <main className={classes.main}>
        <Nav>
          <Button color="inherit" onClick={() => window.location =  "/sign_in"} data-role='sign-in-button'>Sign In</Button>
        </Nav>

        <CssBaseline />
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5" data-role="form-title">
            Sign Up
          </Typography>
          <form onSubmit={submit} className={classes.form}>
            {signUpError && (
              <ErrorBanner
                message={signUpError}
                open={true}
                dataRole="sign-up-error"
                clearError={clearError}
              />
            )}
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Are you a student or staff member?</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={type}
                onChange={handleChange}
              >
                <MenuItem value="Student">Student</MenuItem>
                <MenuItem value="Staff">Staff</MenuItem>
              </Select>
            </FormControl>

            {resolveFormFieldsForUserType()}

            <Button
              variant="contained"
              size="medium"
              color="primary"
              type="submit"
              fullWidth
              data-role="sign-up-submit"
              className={classes.submit}
            >
              Sign up
            </Button>
          </form>
        </Paper>
      </main>
    </div>
  )
}

SignUp.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignUp);
