import React, {Fragment} from 'react'
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {Link} from 'react-router-dom'
import {Car, School, Domain, Briefcase, CurrencyUsd, MapMarker} from 'mdi-material-ui'
import {Salaries} from '../components/Salaries';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
    marginBottom: '30px',
    maxWidth: '1000px',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  link: {
    textDecoration: 'none'
  },
  svgIcon: {
    color: 'gray'
  },
  spanText: {
    verticalAlign: 'top',
    paddingLeft: '15px'
  },
  divText: {
    display: 'inline-block',
    verticalAlign: 'top',
    paddingLeft: '15px'
  },
  tileItem: {
    paddingTop: '15px',
    fontSize: '18px'
  },
  salaries: {
    marginTop: '10px',
    paddingLeft: '0'
  }
}));

export function ProgramCard({program, key}) {
  const classes = useStyles();
  const stringToCurrency = (val) => {
    if (val) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(String(val).replace(/,/, ''))
    }
  }

  return (
    <Paper className={classes.root} key={key} data-role="program-overview">
      <Link to={'/program/' + program.Id} className={classes.link}>
        <Typography variant="h5" component="h3">
          {program.Name}
        </Typography>
      </Link>
      <Typography variant="h6" component="h6">
        {program.School_Organization_Name__r && program.Organization_Type__c && (
          <Fragment>
            <span>{program.School_Organization_Name__r.Name}</span> <span style={{color: '#a29f9f'}}>({program.Organization_Type__c})</span>
          </Fragment>
        )}
      </Typography>
      <Typography component="div">
        <div className={classes.tileItem}>
          {program.Specialty_Degree_Name__c && program.Degree_Level_Credential_Offered__c && (
            <Fragment>
              <School className={classes.svgIcon}/>
              <span className={classes.spanText}>
                {program.Degree_Level_Credential_Offered__c}, {program.Specialty_Degree_Name__c}
              </span>
            </Fragment>
          )}

          <div>
            {program.Career_Area__c && (
              <Fragment>
                <Briefcase className={classes.svgIcon}/>
                <span className={classes.spanText}>
                {program.Career_Area__c}
              </span>
              </Fragment>
            )}
          </div>

          {program.Regions__c && (
            <Fragment>
              <MapMarker className={classes.svgIcon}/>
              <span className={classes.spanText}>
                {program.Regions__c}
              </span>
            </Fragment>
          )}
        </div>
      </Typography>
    </Paper>
  )
}

export default ProgramCard
