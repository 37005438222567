import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import logo from '../NextGenTalent_logo.png'

const styles = theme => ({
  fullWidthLogoContainer: {
    width: '100%',
  },
  logoContainer: {
    padding: '18px',
    width: '400px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '45px'
  },
  logo: {
    maxWidth: '350px'
  }
});


function LogoBanner({classes}) {
  return (
    <div className={classes.fullWidthLogoContainer}>
      <div className={classes.logoContainer}>
        <img className={classes.logo} src={logo} alt="NextGen Talent Logo"/>
      </div>
    </div>
  )
}

LogoBanner.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LogoBanner);