import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import ErrorBanner from './ErrorBanner'
import AuthApi from "../../auth/AuthApi";
import { AuthContext } from '../../contexts/AuthContext'
import LogoBanner from './LogoBanner'
import Cookies from 'js-cookie'
import { Nav } from '../components/Nav';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(20),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  avatar: {
    margin: theme.spacing(),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  submit: {
    marginTop: theme.spacing(2),
  },
  signInForm: {
    marginBottom: theme.spacing(2),
  },
  input: {
    marginBottom: '1.3rem',
  },
  errorText: {
    backgroundColor: theme.palette.error.dark,
  },
  closeIcon: {
    fontSize: 20,
  },
  icon: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  fullWidthLogoContainer: {
    width: '100%',
    margin: '0 auto'
  }
});

export function SignIn({ classes }) {
  const [email, setEmail] = useState()
  const [signInError, setSignInError] = useState(false)
  const [password, setPassword] = useState()
  const authContext = useContext(AuthContext)

  const submit = (e) => {
    e.preventDefault()
    new AuthApi().signIn({ email, password })
      .then((response) => {
        let data = response.data
        authContext.setIsAuthenticated(true)
        Cookies.set('user', JSON.stringify(data.user))
        authContext.setCurrentUser(data.user)
      })
      .catch(() => {
        setSignInError(true)
      })
  }

  const errorContent = () => (
    <ErrorBanner
      dataRole="sign-in-error"
      clearError={clearError}
      open={signInError}
      message="Incorrect email and/or code"
    />
  )

  const clearError = () => {
    setSignInError(false)
  }

  const setPasswordValue = (e) => {
    setPassword(e.target.value)
    clearError()
  }

  const setEmailValue = (e) => {
    setEmail(e.target.value)
    clearError()
  }

  return (
    <div>
      <Nav />
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form onSubmit={submit} className={classes.form}>
            {signInError && (
              errorContent()
            )}
            <Input
              name="email"
              label="Email"
              className={classes.input}
              fullWidth
              margin="normal"
              placeholder="Email"
              autoComplete="email"
              onChange={setEmailValue}
              error={signInError}
              autoFocus
              required
            />
            <Input
              name="code"
              type="text"
              label="Code"
              className={classes.input}
              onChange={setPasswordValue}
              fullWidth
              required
              error={signInError}
              margin="normal"
              placeholder="Code"
            />
            <Button
              variant="contained"
              size="medium"
              color="primary"
              type="submit"
              fullWidth
              data-role="sign-in-submit"
              className={classes.submit}
            >
              Sign in
            </Button>
          </form>
        </Paper>
      </main>
    </div>
  )
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignIn);
