import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core'
import Slider from '@material-ui/core/Slider';
import SfApi from '../components/SfApi'
import { FilterContext } from '../../contexts/FilterContext';
import { Information } from 'mdi-material-ui'
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: '35px',
  },
  salaryMarks: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  infoTooltip: {
    fontSize: '14px',
    lineHeight: '20px'
  },
  iconTextWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  infoIcon: {
    marginLeft: '5px',
    color: 'gray',
    fontSize: '18px'
  }
}));

export const MedianSalaryContainer = ({ connConfig, ...props }) => {
  const filterContext = useContext(FilterContext)
  const [minSalary, setMinSalary] = useState()
  const [maxSalary, setMaxSalary] = useState()
  const [initialMinSalary, setInitialMinSalary] = useState()
  const [initialMaxSalary, setInitialMaxSalary] = useState()
  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    if(!initialMinSalary && !initialMaxSalary) {
      SfApi.getSalaryRange().then(({ minSalary, maxSalary }) => {
        setInitialMinSalary(minSalary)
        setInitialMaxSalary(maxSalary)
      })
      // const conn = new jsforce.Connection(connConfig);
      // conn.query(`SELECT MIN(Median_Salary_for_Occupations_Linked_to__c) minSalary, MAX(Median_Salary_for_Occupations_Linked_to__c) maxSalary FROM Postsecondary_Program__c WHERE Median_Salary_for_Occupations_Linked_to__c != -1`, function(err, res) {
      //   const data = res.records[0]
      //   setInitialMinSalary(data.minSalary)
      //   setInitialMaxSalary(data.maxSalary)
      // })
    }
  }, [])

  const onCostSliderChange = (a, newValues) => {
    const min = parseInt(newValues[0])
    setMinSalary(min)
    if (min !== initialMinSalary) {
      filterContext.updateFilter('minSalary', min)
    }

    const max = parseInt(newValues[1])
    setMaxSalary(max)
    if (max !== initialMaxSalary) {
      filterContext.updateFilter('maxSalary', max)
    }
  }

  const formatCurrency = (val) => {
    return val.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    }).slice(0, -3)
  }

  return (
    <div className={classes.container} data-role="container__salary-filter">
      <div className={classes.iconTextWrapper}>
        <Typography type="caption" variant="caption" color="textSecondary" data-role="label__salary-filter">
          Median Salary
        </Typography>
        <Tooltip title={
          <div className={classes.infoTooltip}>
            Please note that filtering may exclude certain programs from appearing in your search results. <br></br><br></br>
            For example, pre-apprenticeship programs do not generally train people for direct employment,
            which is what we use to determine median salary. As a result, if you filter for median salary,
            these programs will not appear.
          </div>
        } placement="top">
          <Information className={classes.infoIcon} />
        </Tooltip>
      </div>
      <div>
        {!initialMinSalary && !initialMaxSalary && (
          <Slider
            defaultValue={[0, 100000]}
            step={100}
            min={0}
            max={100000}

          />
        )}
        {initialMinSalary && initialMaxSalary && (
          <div>
            <Slider
              defaultValue={[filterContext.filters.minSalary || initialMinSalary, filterContext.filters.maxSalary || initialMaxSalary]}
              onChange={onCostSliderChange}
              step={100}
              min={initialMinSalary}
              max={initialMaxSalary}
            />
            <div className={classes.salaryMarks}>
              <Typography color="textSecondary" variant="caption" data-role="label__min-salary">
                <span>
                  <strong>
                    MIN:
                  </strong>
                  &nbsp;{formatCurrency(initialMinSalary)}
                </span>
              </Typography>
              <Typography color="textSecondary" variant="caption" data-role="label__salary-range">
                {formatCurrency(minSalary || initialMinSalary)}
                &nbsp;-&nbsp;{formatCurrency(maxSalary || initialMaxSalary)}
              </Typography>
              <Typography color="textSecondary" variant="caption" data-role="label__max-salary">
                  <strong>
                    MAX:
                  </strong>
                  &nbsp;
                {formatCurrency(initialMaxSalary)}
              </Typography>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
