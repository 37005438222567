import React, { useEffect } from "react";
import ReactGA from 'react-ga4';

export const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    if(!window.location.host.match(/localhost/)) {
      ReactGA.set({
        page,
        ...options
      });
      ReactGA.send({ hitType: 'pageview', page } );
    }
  };

  const HOC = props => {
    useEffect(() => trackPage(`${props.location.pathname}${props.location.search}`), [
      props.location.pathname, props.location.search
    ]);

    return <WrappedComponent {...props} />;
  };

  return HOC;
};
