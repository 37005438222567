import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import ScrollToTop from '../components/ScrollToTop'
import SignIn from '../components/SignIn'
import SignUp from '../components/SignUp'
import ProgramsRoot from '../containers/Root';
import AuthApi from '../../auth/AuthApi'
import ProgramContainer from '../containers/ProgramContainer';
import UploadDashboardContainer from '../../admin/containers/UploadDashboardContainer'
import { AuthContext } from "../../contexts/AuthContext";
import { FilterProvider } from "../../contexts/FilterContext";
import { withTracker } from '../components/withTracker'
import Cookies from 'js-cookie'
import ReactGA from 'react-ga4';

export function AppContainer(props) {
  const authContext = useContext(AuthContext)

  useEffect(() => {
    ReactGA.initialize('G-PV2DBGDCND');
    new AuthApi()
      .reAuthUser()
      .then(() => {
        authContext.setIsAuthenticated(true)
        let user = Cookies.get('user')
        authContext.setCurrentUser(JSON.parse(user))
      })
      .catch(() => {
        authContext.setIsAuthenticated(false)
      });
  }, [])

  return (
    <ScrollToTop>
      <Switch>
        { authContext.isAuthenticated && (
            <Redirect from="/sign_in" to="/"/>
          )}
        <Route
          exact
          path="/sign_in"
          component={withTracker(SignIn, {})}
          />
        <Route
          exact
          path="/sign_up"
          component={withTracker(SignUp, {})}
        />
        { !authContext.isAuthenticated && authContext.shouldRedirectToLogin && props.location.pathname !== '/sign_in' && (
          <Redirect to="/sign_in" />
        )}
        { authContext.isAuthenticated && (
          <Route exact path='/admin/ipeds_uploads/new' component={withTracker(UploadDashboardContainer, {})}/>
        )}
        { authContext.isAuthenticated && (
          <Route exact path='/admin/occupations_uploads/new' component={withTracker(UploadDashboardContainer, {})}/>
        )}
        { authContext.isAuthenticated && (
          <Route exact path='/admin/programs_uploads/new' component={withTracker(UploadDashboardContainer, {})}/>
        )}
        { authContext.isAuthenticated && authContext.currentUser.id && (
          <FilterProvider>
            <Route exact path='/' component={withTracker(ProgramsRoot, {})}/>
            <Route path='/program/:id' component={withTracker(ProgramContainer, {})}/>
          </FilterProvider>
        )}
      </Switch>
    </ScrollToTop>
  )
}

export default withRouter(AppContainer);
