import React, { useEffect, useState, useContext } from 'react';
import Typeahead from '../components/Typeahead'
import jsforce from 'jsforce';
import { FilterContext } from '../../contexts/FilterContext'
import { MultiParams } from '../components/SfApi'

const ProgramFilterContainer = ({ label, pluralLabel, attribute, getAttrubute, connConfig, urlParam, initialValue, onValuesFetched, ...extraProps }) => {
  const [suggestions, setSuggestions] = useState([])
  const filterContext = useContext(FilterContext)

  const mapSuggestions = (values) => {
    let data = [];
    values.forEach((val) => {
      if (val && !val.includes(';')) {
        data.push({ label: val, value: val });
      } else if (val && val.includes(';') && MultiParams.includes(attribute)) {
        const vals = val.split(";");
        vals.map((val) => {
          data.push({ label: val, value: val });
        });
      }
    });

    return data;
  };

  useEffect(() => {
    if(connConfig && suggestions.length === 0) {
      const conn = new jsforce.Connection(connConfig);
      conn.sobject('Postsecondary_Program__c')
        .find({'Display_in_Online_Directory__c': 'Yes'})
        .select(attribute)
        .execute((err, records) => {
          const suggestionData = [...new Set(records.map((record) => getAttrubute ? getAttrubute(record) : record[attribute]))].sort();
          const data = mapSuggestions(suggestionData);
          const uniqueData = data.filter((el, index) =>
            data.findIndex(obj => obj.value === el.value) === index
          ).sort(function(a, b){
            if(a.value < b.value) { return -1; }
            if(a.value > b.value) { return 1; }
            return 0;
          })
          filterContext.saveFilterListValues(attribute, uniqueData.map((datum) => datum.value))
          setSuggestions(uniqueData)
          onValuesFetched && onValuesFetched.resolve();
        })
    } else if (filterContext.filterListValues[attribute]) {
      setSuggestions(mapSuggestions(filterContext.filterListValues[attribute]))
      onValuesFetched && onValuesFetched.resolve();
    }
  }, [connConfig])

  const onChange = (selection) => {
    if(Array.isArray(selection)) {
      filterContext.updateFilter(urlParam, selection.map((s) => s.value))
    } else if (selection) {
      filterContext.updateFilter(urlParam, selection.value)
    } else {
      filterContext.removeFilter(urlParam)
    }
  }

  return (
    <Typeahead
      label={label}
      attribute={attribute}
      pluralLabel={pluralLabel}
      suggestions={suggestions}
      onChange={onChange}
      initialValue={initialValue}
      showRecommended={extraProps.isMulti}
      {...extraProps}
    />
  )
}

export default ProgramFilterContainer
