import Api from './Api'

function UserApi() {
  this.path = '/api/v1/users';

  this.createUser = ({ type, firstName, lastName, email, password, title, phone, organizationAffiliation, schools, studentsServed, gradesServed }) => {
    return Api.post(this.path, {
      user: {
        type: type,
        first_name: firstName,
        last_name: lastName,
        email,
        password,
        title,
        phone,
        organization_affiliation: organizationAffiliation,
        schools,
        students_served: studentsServed,
        grades_served: gradesServed
      }
    })
      .then(({ data }) => data);
  };
}

export default UserApi;
