import React, { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import useForm from '../../hooks/useForm'
import FormHelperText from '@material-ui/core/FormHelperText';
import DataUploadsApi from '../../auth/DataUploadsApi'
import { AuthContext } from "../../contexts/AuthContext";
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  uploadForm: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-around'
  },
  formBtn: {
    width: 'fit-content',
  },
  formBtnRt: {
    width: 'fit-content',
    marginLeft: 'auto'
  },
  successMessage: {
    color: 'green',
    fontWeight: 'bold'
  }
}));

function UploadDashboardContainer() {
  const classes = useStyles();
  const authContext = useContext(AuthContext)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [formResponse, setFormResponse] = useState(undefined)
  const [formConfig, setFormConfig] = useState(gon.form_config)

  const handleSubmitUpload = () => {
    setIsSubmitting(true)
    let formData = new FormData()
    formData.append('file', values.file)
    formData.append('year', values.year)
    formData.append('upload_type', formConfig.upload_type)

    new DataUploadsApi().createUpload(formConfig.path, formData).then((data) => {
      setIsSubmitting(false)
      setFormResponse(data)
      console.log(data)
    });
  }

  const validateForm = () => {
    let errors = {}

    if (values.file === undefined) {
      errors.file = "Please choose a file."
    }

    if (formConfig.year == true && values.year.length <= 0) {
      errors.year = "Please select a year."
    }

    console.log(errors)

    return errors
  }

  const UploadErrors = () => {
    return(
      <ul>
        {formResponse.errors.map((error) => {
          return(
            <li>{error}</li>
          )
        })}
      </ul>
    )
  }

  const {values, errors, handleOnChange, handleOnSubmit, handleFileFieldOnChange} = useForm(
    handleSubmitUpload,
    {
      file: undefined,
      year: ''
    },
    validateForm
  )

  return(
    <Container fixed>
      <Grid container justify='center'>
        <Grid item xs={8}>
          <Paper className={classes.paper}>
            {/*<Grid container*/}
                  {/*direction="column"*/}
                  {/*justify='center'*/}
                  {/*alignItems="center"*/}
            {/*>*/}
              <Grid item xs={12}>
                <form className={classes.uploadForm} onSubmit={handleOnSubmit}>
                  <FormControl className={classes.formControl}>
                    <Button
                      className={classes.formBtn}
                      variant="contained"
                      component="label"
                    >
                      Choose {formConfig.label} File
                      <input
                        type="file"
                        name="file"
                        accept=".csv, .CSV"
                        hidden
                        onChange={handleFileFieldOnChange}
                      />
                    </Button>
                    {values.file && <FormHelperText id="file-name-text">{values.file.name}</FormHelperText>}
                    {errors.file && <FormHelperText error id="file-error-text">{errors.file}</FormHelperText>}
                  </FormControl>

                  {formConfig.year == true && <FormControl className={classes.formControl}>
                    <InputLabel id="ipeds-year">Year<sup>*</sup></InputLabel>
                    <Select
                      id="ipeds-year"
                      value={values.year}
                      onChange={handleOnChange}
                      inputProps={{
                        name: 'year'
                      }}
                    >
                      <MenuItem value=''>Select Year</MenuItem>
                      <MenuItem value={2019}>2019</MenuItem>
                      <MenuItem value={2020}>2020</MenuItem>
                      <MenuItem value={2021}>2021</MenuItem>
                      <MenuItem value={2022}>2022</MenuItem>
                      <MenuItem value={2023}>2023</MenuItem>
                      <MenuItem value={2024}>2024</MenuItem>
                      <MenuItem value={2025}>2025</MenuItem>
                    </Select>
                    {errors.year && <FormHelperText error id="year-error-text">{errors.year}</FormHelperText>}
                  </FormControl>}


                  <FormControl className={classes.formControl}>
                    <Button
                      className={classes.formBtnRt}
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? 'Uploading...' : 'Upload'}
                    </Button>
                  </FormControl>
                </form>
                {isSubmitting && <LinearProgress />}
              </Grid>

              {(formResponse !== undefined) && <Grid item xs={12}>
                <div>
                  <Typography variant="subtitle1" gutterBottom>
                    <span className={classes.successMessage}>{formResponse}</span>
                  </Typography>
                </div>
              </Grid>}
            {/*</Grid>*/}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}

export default UploadDashboardContainer
