import React from 'react'
import ReactDOM from 'react-dom'
import AppContainer from '../programs/containers/AppContainer';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from '../contexts/AuthContext'

function resolveAppendmentPoint() {
  if (window.location.pathname.includes("admin")) {
    return document.getElementsByClassName('main-content')[0].appendChild(document.createElement('div'))
  } else {
    return document.body.appendChild(document.createElement('div'))
  }
}

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <Router>
      <AuthProvider>
        <AppContainer />
      </AuthProvider>
    </Router>,
    resolveAppendmentPoint(),
  )
})
