import React from 'react';
import Cookies from 'js-cookie'

export const AuthContext = React.createContext()

export class AuthProvider extends React.Component {
  constructor(props) {
    super(props)

    this.setIsAuthenticated = (bool) => {
      this.setState({
        isAuthenticated: bool,
        shouldRedirectToLogin: !bool
      })
    }

    this.setCurrentUser = (user) => {
      this.setState({
        currentUser: {
          id: user.id,
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName
        }
      })
    }

    this.state = {
      isAuthenticated: false,
      shouldRedirectToLogin: false,
      currentUser: {},
      setIsAuthenticated: this.setIsAuthenticated,
      setCurrentUser: this.setCurrentUser
    }
  }

  render() {
    return (
      <AuthContext.Provider value={this.state}>
        {this.props.children}
      </AuthContext.Provider>
    )
  }
}

export const AuthConsumer = AuthContext.Consumer;
