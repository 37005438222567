import { useState, useEffect, useCallback } from 'react';

export const useForm = (callback, initialState = {}, validate) => {
  const [values, setValues] = useState(initialState);
  const [errors, setErrors] = useState({});

  const handleOnChange = (event) => {
    errors[event.target.name] = ''
    setValues({...values, [event.target.name]: event.target.value})
  }

  const handleFileFieldOnChange = (event) => {
    errors[event.target.name] = ''
    setValues({...values, [event.target.name]: event.target.files[0]})
  }

  const handleOnSubmit = (event) => {
    event && event.preventDefault()

    let errors = validate(values)

    if (Object.keys(errors).length === 0) {
      callback()
      setValues(initialState)
    } else {
      setErrors(errors)
    }
  }

  const handleOnCancel = () => {
    setErrors({})
    setValues(initialState)
  }

  return {
    handleOnChange,
    handleOnSubmit,
    errors,
    values,
    handleOnCancel,
    handleFileFieldOnChange
  };
}

export default useForm
