import React, { useContext, useEffect, useState } from 'react';
import { FilterContext } from '../../contexts/FilterContext';
import { Button, Divider, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'
import ProgramFilterContainer from './ProgramFilterContainer'
import { MedianSalaryContainer } from './MedianSalaryContainer';
import { green } from '@material-ui/core/colors';
import ReactGA from 'react-ga4';
import {Information} from "mdi-material-ui";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles(theme => ({
  toolbar: theme.mixins.toolbar,
  container: {
    padding: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
  },
  filterContainer: {
    marginBottom: '1rem',
    width: '100%',
  },
  searchButton: {
    color: 'white',
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
    marginTop: '30px',
  },
  infoTooltip: {
    fontSize: '14px',
    lineHeight: '20px'
  },
  iconTextWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  infoIcon: {
    marginLeft: '5px',
    color: 'gray',
    fontSize: '18px'
  },
  searchTypography: {
    marginTop: '30px',
    color: 'rgba(0, 0, 0, 0.54)',
    padding: '0',
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
    fontWeight: '400',
    lineHeight: '1',
    letterSpacing: '0.00938em'
  }
}));

export const FiltersContainer = (props) => {
  const classes = useStyles();
  const filterContext = useContext(FilterContext)
  const handleNameQueryChange = event => {
    if(event.target.value !== '') {
      filterContext.updateFilter('search', event.target.value);
    } else {
      filterContext.removeFilter('search')
    }
  };

  function Deferred() {
    var self = this;
    this.promise = new Promise(function(resolve, reject) {
      self.reject = reject
      self.resolve = resolve
    })
  };

  const fetchCareerAreaDef = new Deferred();
  const fetchDegreeLevelDef = new Deferred();
  const fetchSpecialtyDegreeLevelDef = new Deferred();
  const fetchSchoolOrgNameDef = new Deferred();
  const fetchOrgTypeDef = new Deferred();
  const fetchTargetOccDef = new Deferred();
  const fetchRegionsDef = new Deferred();

  Promise.all([
    fetchCareerAreaDef.promise,
    fetchDegreeLevelDef.promise,
    fetchSpecialtyDegreeLevelDef.promise,
    fetchSchoolOrgNameDef.promise,
    fetchOrgTypeDef.promise,
    fetchTargetOccDef.promise,
    fetchRegionsDef.promise,
  ]).then(() => {
    filterContext.setFilterValuesFetched(true)
  });

  const onSubmit = (event) => {
    event.preventDefault()
    ReactGA.set({ filters: filterContext.filters });
    ReactGA.event({
      category: 'User',
      action: 'Filters Applied',
    });
    props.handleSubmit()
  }

  return (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <form className={classes.container} onSubmit={onSubmit}>
        <Typography className={classes.searchTypography} type="caption" variant="caption">
          <div className={classes.iconTextWrapper}>
            Search
            <Tooltip title={
              <div className={classes.infoTooltip}>
                To search for an exact phrase use quotation marks (e.g.<strong>"financial analyst"</strong>). Without
                quotation marks, the search phrase <strong>financial analyst</strong> will return all programs that
                mention the two words, independently of one another. To search for programs that
                include at least one of the search terms, use capital <strong>OR</strong> between the terms (e.g. electrician <strong>OR</strong> electrical).
              </div>
            } placement="top">
              <Information className={classes.infoIcon} />
            </Tooltip>
          </div>
        </Typography>
        <TextField
          id="outlined-name"
          value={filterContext.filters.search || ''}
          onChange={handleNameQueryChange}
          margin="normal"
          fullWidth
          placeholder="enter search terms"
          data-role="program-search"
        />
        <div className={classes.filterContainer}>
          <ProgramFilterContainer
            attribute="Career_Area__c"
            urlParam="careerArea"
            connConfig={props.connConfig}
            onValuesFetched={fetchCareerAreaDef}
            label="Career Area"
            initialValue={filterContext.filters.careerArea}
            isClearable
            isMulti
          />
          <ProgramFilterContainer
            attribute="Degree_Level_Credential_Offered__c"
            urlParam="degreeLevelCredentialOffered"
            connConfig={props.connConfig}
            onValuesFetched={fetchDegreeLevelDef}
            label="Degree Level / Credential Offered"
            pluralLabel="Degree Levels / Credentials Offered"
            initialValue={filterContext.filters.degreeLevelCredentialOffered}
            isMulti
          />
          <ProgramFilterContainer
            attribute="Specialty_Degree_Name__c"
            urlParam="specialtyDegreeName"
            connConfig={props.connConfig}
            onValuesFetched={fetchSpecialtyDegreeLevelDef}
            label="Program of Study"
            pluralLabel="Programs of Study"
            initialValue={filterContext.filters.specialtyDegreeName}
            isClearable
            isMulti
          />
          <ProgramFilterContainer
            attribute="School_Organization_Name__r.Name"
            getAttrubute={(record) => record?.School_Organization_Name__r?.Name}
            urlParam="organizationName"
            connConfig={props.connConfig}
            onValuesFetched={fetchSchoolOrgNameDef}
            label="Organization Name"
            pluralLabel="Organization Names"
            initialValue={filterContext.filters.organizationName}
            isClearable
            isMulti
          />
          <ProgramFilterContainer
            attribute="Organization_Type__c"
            urlParam="organizationType"
            connConfig={props.connConfig}
            onValuesFetched={fetchOrgTypeDef}
            label="Organization Type"
            pluralLabel="Organization Types"
            initialValue={filterContext.filters.organizationType}
            isClearable
            isMulti
          />
          <ProgramFilterContainer
            attribute="Target_Occupations__c"
            urlParam="targetOccupations"
            connConfig={props.connConfig}
            onValuesFetched={fetchTargetOccDef}
            label="Target Occupation"
            pluralLabel="Target Occupations"
            initialValue={filterContext.filters.targetOccupations}
            isClearable
            isMulti
          />
          <ProgramFilterContainer
            attribute="Regions__c"
            urlParam="regions"
            connConfig={props.connConfig}
            onValuesFetched={fetchRegionsDef}
            label="Regions"
            pluralLabel="Regions"
            initialValue={filterContext.filters.regions}
            isClearable
            isMulti
          />
          <MedianSalaryContainer
            connConfig={props.connConfig}
          />
        </div>
        <Button
          className={classes.searchButton}
          data-role="program-search-button"
          variant="contained"
          fullWidth
          type="submit"
        >
          Search
        </Button>
      </form>
    </div>
  )
}
