import React from 'react';
import Typography from "@material-ui/core/Typography";
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  salaries: {
    height: '100px',
    width: '450px',
  },
  bottomSalaryContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '24%',
  },
  medianSalaryContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
  },
  topSalaryContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '24%',
  },
  bottomSalary: {
    height: '25px',
    backgroundImage: 'linear-gradient(to right, #1D2671 , #C33764)',
  },
  medianSalary: {
    height: '25px',
    backgroundImage: 'linear-gradient(to right, #C33764 , #ec38bc)',
  },
  topSalary: {
    height: '25px',
    backgroundImage: 'linear-gradient(to right, #ec38bc , #fdeff9)',
  },
  bottomSalaryValue: {
    display: 'flex',
    flexDirection: 'column',
  },
  medianSalaryValue: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  topSalaryValue: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'right',
  },
  salaryValues: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));


export function Salaries(props) {
  const classes = useStyles();

  return (
    <div className={classes.salaries}>
      <div className={classes.salaryValues}>
        <div className={classes.bottomSalaryContainer} data-role="salary_25pct">
          <div className={classes.bottomSalary}></div>
          <div className={classes.bottomSalaryValue}>
            <Typography variant="h6">
              {props.twentyFifth}
            </Typography>
            <Typography color="textSecondary" variant="caption">
              Bottom 25%
            </Typography>
          </div>
        </div>
        <div className={classes.medianSalaryContainer} data-role="salary_med">
          <div className={classes.medianSalary}></div>
          <div className={classes.medianSalaryValue}>
            <Typography variant="h6">
              {props.median}
            </Typography>
            <Typography color="textSecondary" variant="caption">
              Median
            </Typography>
          </div>
        </div>
        <div className={classes.topSalaryContainer} data-role="salary_75pct">
          <div className={classes.topSalary}></div>
          <div className={classes.topSalaryValue}>
            <Typography variant="h6">
              {props.seventyFifth}
            </Typography>
            <Typography color="textSecondary" variant="caption">
              Top 25%
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Salaries
