import Api from './Api'

function AuthApi() {
  this.path = '/api/v1/auth';

  this.signIn = ({ email, password }) => {
    return Api.post('/api/v1/sign_in', {
      user: {
        email: email,
        password: password,
      }
    }).then(({ data }) => data);
  };

  this.signOut = () => {
    return Api.delete('/api/v1/sign_out')
      .then(({ data }) => data);
  };

  this.reAuthUser = () => {
    return Api.get('/api/v1/')
      .then(({ data }) => data);
  }
}

export default AuthApi;
