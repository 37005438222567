import axios from 'axios'

function DataUploadsApi() {
  const token = document.querySelector('[name="csrf-token"]') || {content: 'no-csrf-token'};

  this.createUpload = (path, formData) => {
    return axios({
      headers: {
        common: {
          'X-CSRF-Token': token.content
        },
        'Content-Type': 'multipart/form-data'
      },
      withCredentials: true,
      url: path,
      method: 'POST',
      data: formData
    }).then((response) => {
      window.location = response.data.redirectTo
    });
  };
}

export default DataUploadsApi;
