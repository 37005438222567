import React, { useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Select from 'react-select';
import { emphasize, makeStyles, useTheme } from '@material-ui/core/styles';
import { ListAlt } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { FilterContext } from '../../contexts/FilterContext';
import { getMatchingFilterListValues } from '../helpers/FiltersHelper';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto',
    minHeight: '32px',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  chipFuzzyMatchFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? '#d5e7ff' : '#b3d4ff',
      0.08,
    ),
  },
  chipFuzzyMatch: {
    backgroundColor: '#d5e7ff',
  },
  chipWithPill: {
    display: 'flex',
    alignItems: 'center',
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    bottom: 6,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
  recommendationMenuLabel: {
    padding: theme.spacing(1, 2, 0),
    fontSize: 13,
    color: theme.palette.grey[600],
    backgroundColor: '#d5e7ff',
  },
  recommendationMenu: {
    boxShadow: `0px 1px 6px -2px ${theme.palette.grey[400]}`,
    backgroundColor: '#d5e7ff',
  },
  recommendationMenuItem: {
    padding: theme.spacing(0.5, 2),
    minHeight: theme.spacing(4),
    color: theme.palette.grey[700],
    backgroundColor: '#d5e7ff',
  },
  icon: {
    color: '#6B7F99',
    marginRight: theme.spacing(0.5),
  },
  pillIcon: {
    color: '#6B7F99',
    height: theme.spacing(2.5),
    marginRight: theme.spacing(0.5),
  }
}));

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

NoOptionsMessage.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  /**
   * Props to be passed on to the wrapper.
   */
  innerProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
};

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]),
};

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
    hasValue
  } = props;

  return (
    <TextField
      fullWidth
      margin="normal"
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
      value={hasValue ? 'HAS VALUE' : ''}
    />
  );
}

Control.propTypes = {
  /**
   * Children to render.
   */
  children: PropTypes.node,
  /**
   * The mouse down event and the innerRef to pass down to the controller element.
   */
  innerProps: PropTypes.shape({
    onMouseDown: PropTypes.func.isRequired,
  }).isRequired,
  innerRef: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]).isRequired,
  selectProps: PropTypes.object.isRequired,
};

function Option(props) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

Option.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  /**
   * props passed to the wrapping element for the group.
   */
  innerProps: PropTypes.shape({
    id: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    onMouseMove: PropTypes.func.isRequired,
    onMouseOver: PropTypes.func.isRequired,
    tabIndex: PropTypes.number.isRequired,
  }).isRequired,
  /**
   * Inner ref to DOM Node
   */
  innerRef: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]).isRequired,
  /**
   * Whether the option is focused.
   */
  isFocused: PropTypes.bool.isRequired,
  /**
   * Whether the option is selected.
   */
  isSelected: PropTypes.bool.isRequired,
};

function Placeholder(props) {
  const { selectProps, innerProps = {}, children } = props;
  return (
    <Typography color="textSecondary" className={selectProps.classes.placeholder} {...innerProps}>
      {children}
    </Typography>
  );
}

Placeholder.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  /**
   * props passed to the wrapping element for the group.
   */
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

SingleValue.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  /**
   * Props passed to the wrapping element for the group.
   */
  innerProps: PropTypes.any.isRequired,
  selectProps: PropTypes.object.isRequired,
};

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

ValueContainer.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired,
};

function MultiValue(props) {
  const isFuzzyMatch = !!props.data?.isFuzzyMatch

  return (
    <Chip
      tabIndex={-1}
      label={isFuzzyMatch ? <><ListAlt className={props.selectProps.classes.pillIcon} />{props.children}</> : props.children}
      title={isFuzzyMatch ? `Matches all values containing '${props.data.label.replace(/\*$/, '')}'` : null}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
        [props.selectProps.classes.chipFuzzyMatch]: isFuzzyMatch,
        [props.selectProps.classes.chipFuzzyMatchFocused]: props.isFocused && isFuzzyMatch,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

MultiValue.propTypes = {
  children: PropTypes.node,
  isFocused: PropTypes.bool.isRequired,
  removeProps: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    onMouseDown: PropTypes.func.isRequired,
    onTouchEnd: PropTypes.func.isRequired,
  }).isRequired,
  selectProps: PropTypes.object.isRequired,
};

function Menu(props) {
  var recommendation = props.selectProps?.fuzzyMatchSearchProps?.value || '';
  const onRecommendationClick = () => {
    let selectValue = props.selectProps?.fuzzyMatchSearchProps?.selectValue || [];
    if (!Array.isArray(selectValue)) {
      selectValue = [selectValue]
    }
    const val = [...selectValue, {value: `${recommendation}*`, label: recommendation, isFuzzyMatch: true}];
    props.selectProps.fuzzyMatchSearchProps.onChange(val)
  };

  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {!!recommendation && props.selectProps?.fuzzyMatchSearchProps?.showRecommended && (
        <div className={props.selectProps.classes.recommendationMenu}>
          <div className={props.selectProps.classes.recommendationMenuLabel}>Recommended:</div>
          <MenuItem className={props.selectProps.classes.recommendationMenuItem} onClick={() => onRecommendationClick()}>
            <ListAlt className={props.selectProps.classes.icon} />
            {`Select all '${recommendation}' ${props.selectProps?.fuzzyMatchSearchProps.pluralLabel} (${props.selectProps?.fuzzyMatchSearchProps.matchingFilterValues.length})`}
          </MenuItem>
        </div>
      )}
      {props.children}
    </Paper>
  );
}

Menu.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.element.isRequired,
  /**
   * Props to be passed to the menu wrapper.
   */
  innerProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
};

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

const applyFuzzyMatch = (val) => {
  const v = !!val ? val : {};

  if ((v['value'] ?? '').match(/\*$/)) {
    return {...val, label: val.label.replace(/\*$/, ''), isFuzzyMatch: true}
  } else {
    return {...val, label: val.label.replace(/\*$/, ''), isFuzzyMatch: false}
  }
}

const setInitialValue = (val) => {
  if(Array.isArray(val)) {
    return val.map((name) => { return applyFuzzyMatch({ label: name, value: name }) })
  } else if (val) {
    return applyFuzzyMatch({label: val, value: val})
  } else {
    null
  }
}

export default function Typeahead({ label, pluralLabel, attribute, suggestions, onChange, initialValue, showRecommended, ...extraProps }) {
  const classes = useStyles();
  const theme = useTheme();
  const filterContext = useContext(FilterContext);
  const [value, setValue] = React.useState(setInitialValue(initialValue));
  const [isOpen, setIsOpen] = React.useState(false);
  const [recommendedSearch, setRecommendedSearch] = React.useState();
  const [matchingFilterValues, setMatchingFilterValues] = React.useState([]);
  const [inputValue, setInputValue] = React.useState('');

  function handleChange(value) {
    let v = value;
    if(Array.isArray(value)) {
      v = value.map((val) => applyFuzzyMatch(val))
    } else if (!!value) {
      v = applyFuzzyMatch(value);
    }
    setIsOpen(false);
    setInputValue('');
    setValue(v);
    onChange(v);
  }

  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  };

  useEffect(() => {
    if(initialValue) {
      setValue(setInitialValue(initialValue))
    }
  }, [initialValue]);

  useMemo(() => {
    if (!!recommendedSearch) {
      const matchingFilters = getMatchingFilterListValues({ attribute, val: recommendedSearch, filterListValues: filterContext.filterListValues});
      setMatchingFilterValues(matchingFilters)
    }
  }, [recommendedSearch]);

  const onInputChange = (a,b,c) => {
    setInputValue(a)
    setRecommendedSearch(a)
  };

  return (
    <div className={classes.root}>
      <NoSsr>
        <Select
          classes={classes}
          styles={selectStyles}
          inputId={`${label}-select`}
          fuzzyMatchSearchProps={{
            showRecommended,
            value: recommendedSearch,
            label,
            selectValue: value,
            matchingFilterValues,
            pluralLabel: pluralLabel ?? label,
            onChange: handleChange
          }}
          TextFieldProps={{
            label: label,
            InputLabelProps: {
              shrink: true,
              htmlFor: `${label}-select`,
            },
          }}
          onInputChange={onInputChange}
          placeholder=""
          options={suggestions}
          components={components}
          value={value}
          inputValue={inputValue}
          onChange={handleChange}
          menuIsOpen={isOpen}
          onMenuOpen={() => setIsOpen(true)}
          onMenuClose={() => setIsOpen(false)}
          {...extraProps}
        />
      </NoSsr>
    </div>
  );
}
