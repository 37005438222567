import axios from 'axios';
import { getMatchingFilterListValues } from '../helpers/FiltersHelper';

const selectAttributes = [
  'Id',
  'Name',
  'Career_Area__c',
  'Specialty_Degree_Name__c',
  'Degree_Level_Credential_Offered__c',
  'School_Organization_Name__r.Name',
  'Organization_Type__c',
  'Twenty_fifth_percentile_Salary__c',
  'Seventy_fifth_percentile_Salary__c',
  'Median_Salary_for_Occupations_Linked_to__c',
  'Regions__c'
]

const filterMapping = {
  organizationName: 'School_Organization_Name__r.Name',
  careerArea: 'Career_Area__c',
  targetOccupations: 'Target_Occupations__c',
  degreeLevelCredentialOffered: 'Degree_Level_Credential_Offered__c',
  specialtyDegreeName: 'Specialty_Degree_Name__c',
  organizationType: 'Organization_Type__c',
  regions: 'Regions__c',
  remoteIds: 'Postsecondary_Program__c.id',
}

export const MultiParams = ['Degree_Level_Credential_Offered__c', 'Regions__c', 'Career_Area__c'];

const SfApi = {
  fetchToken() {
    return axios.get('/api/v1/token').then(({ data: { data: { accessToken, version, instanceUrl } } }) => {
      return {
        version: version,
        instanceUrl: instanceUrl,
        accessToken: accessToken,
      }
    });
  },

  getPostSecondaryPrograms({ conn, page, programLimit, filters = {}, remoteIds, callback, filterListValues }) {
    const offset = page ? page * programLimit : 0
    const { search, ...programFilters } = filters;
    const updatedFilters = remoteIds ? { remoteIds, ...programFilters } : programFilters

    if(search) {
      return conn.search(`FIND {${this.escapeSOQLString(filters.search)}} IN ALL FIELDS RETURNING Postsecondary_Program__c(${selectAttributes}${this.filtersQuery(updatedFilters, filterListValues)} ORDER BY Name OFFSET ${offset}) LIMIT ${programLimit}`,
        function(err, res) {
          const records = res ? res.searchRecords : []
          callback(err, records)
        }
      )
    } else {
      return conn.sobject("Postsecondary_Program__c")
        .find(this.filtersQueryObject(updatedFilters, filterListValues))
        .select(selectAttributes)
        .limit(programLimit)
        .skip(offset)
        .sort({ Name : 1 })
        .execute((err, records) => {
          callback(err, records)
        })
    }
  },

  getPostSecondaryProgram(conn, id, callback) {
    conn.sobject("Postsecondary_Program__c").select('*, Contact__r.*, RecordType.*, School_Organization_Name__r.Name').where(`Id = '${id}'`).execute(callback);
  },

  escapeSOQLString(str) {
    return String(str || '').replace(/'/g, "\\'")
  },

  filtersQuery(filters, filterListValues) {
    const filterKeys = Object.keys(filters)

    if(filterKeys.length === 0) {
      return ''
    } else {
      let query = []
      filterKeys.forEach((key) => {
        if(key === 'minSalary' || key === 'maxSalary') {
          return
        }

        if(Array.isArray(filters[key]) && filters[key].length == 0) {
          return
        }

        const attribute = filterMapping[key] ? filterMapping[key] : key
        const filterValues = this.getFilterListValues({ attribute, val: filters[key], filterListValues })
        query.push(`${attribute} ${MultiParams.includes(attribute) ? 'INCLUDES' : 'IN'} (${filterValues.map((k) => `'${this.escapeSOQLString(k)}'`).join(', ')})`)
      })
      if (query.length > 0) {
        return ` WHERE ${query.join(' AND ')}`
      } else {
        return ''
      }
    }
  },

  filtersQueryObject(filters, filterListValues) {
    const filterKeys = Object.keys(filters)

    if(filterKeys.length === 0) {
      return {}
    } else {
      let filterObject = {}
      filterKeys.forEach((key) => {
        if(key === 'minSalary' || key === 'maxSalary') {
          return
        }
        const attribute = filterMapping[key] ? filterMapping[key] : key
        if(Array.isArray(filters[key]) && MultiParams.includes(attribute)) {
          filterObject[attribute] = { $includes: this.getFilterListValues({ attribute, val: filters[key], filterListValues }) }
        } else {
          filterObject[attribute] = this.getFilterListValues({ attribute, val: filters[key], filterListValues })
        }

      })

      return filterObject
    }
  },

  getFilterListValues: ({ attribute, val, filterListValues }) => {
    let valArray = Array.isArray(val) ? val : [val]
    let newValuesArray = []

    valArray.forEach((v) => {
      if (v.trim().match(/\*$/)) {
        const trimmedVal = v.trim().replace(/\*$/, '');
        const matchingValues = getMatchingFilterListValues({ attribute, val: trimmedVal, filterListValues });

        if (matchingValues.length === 0) {
          newValuesArray.push(trimmedVal)
        } else {
          newValuesArray.push(...matchingValues)
        }
      } else {
        newValuesArray.push(v)
      }
    });

    return newValuesArray
  },

  getIpeds({ unitid, cipco, awlevel }) {
    return axios.get(`/api/v1/ipeds?unitid=${unitid}&cipco=${cipco}&awlevel=${awlevel}`).then(({ data }) => {
      return { iped: data }
    });
  },
  getPrograms({ minSalary = '', maxSalary = '' }) {
    return axios.get(`/api/v1/programs?minSalary=${minSalary}&maxSalary=${maxSalary}`).then(({ data }) => {
      return { programs: data }
    });
  },

  getProgram({ remoteId, unitId }) {
    return axios.get(`/api/v1/programs/${remoteId.substring(0, 15)}?unitid=${unitId}`).then(({ data }) => {
      return { program: data }
    });
  },

  getSalaryRange() {
    return axios.get(`/api/v1/salary_range`).then(({ data: { minSalary, maxSalary } }) => {
      return { minSalary, maxSalary }
    });
  },
}

export default SfApi
