import React from 'react'
import {
  AppBar,
  ButtonBase,
  makeStyles,
  Toolbar,
} from '@material-ui/core';
import NextGenTalentLogoWhite from '../NextGenTalent_logo_white.png'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  navBar: {
    display: 'flex',
    height: '83px',
    justifyContent: 'space-between',
    paddingLeft: '4vw',
    paddingRight: '4vw',
    '& button': {
      fontSize: 'calc(1.2 * 1rem)',
      marginLeft: '1.4vw'
    },
    '& a': {
      fontSize: 'calc(1.25 * 1rem)',
      marginLeft: '1.4vw'
    }
  },
  navLogo: {
    maxWidth: '100%',
    maxHeight: '50px',
  }
}));

export const Nav = (props) => {
  const classes = useStyles();

  return (
    <AppBar position="fixed" className={classes.appBar} data-role='navbar'>
      <Toolbar className={classes.navBar}>
        <img src={NextGenTalentLogoWhite} alt="NextGen Talent Logo White" className={classes.navLogo} />
        <div>
          <ButtonBase href="https://get-navigator.nextgentalent.org/overview" onClick={() => window.location.href = 'https://get-navigator.nextgentalent.org/overview'} color="inherit" data-role='overview'>Overview</ButtonBase>
          <ButtonBase href="https://get-navigator.nextgentalent.org/features" onClick={() => window.location.href = 'https://get-navigator.nextgentalent.org/features'} color="inherit" data-role='features'>Features</ButtonBase>
          <ButtonBase href="https://get-navigator.nextgentalent.org/spotlights" onClick={() => window.location.href = 'https://get-navigator.nextgentalent.org/spotlights'} color="inherit" data-role='spotlights'>Spotlights</ButtonBase>
          <ButtonBase href="https://get-navigator.nextgentalent.org/faq" onClick={() => window.location.href = 'https://get-navigator.nextgentalent.org/faq'} color="inherit" data-role='faw'>FAQ</ButtonBase>
          { props.children }
        </div>
      </Toolbar>
    </AppBar>
  )
}
