import React, { useState, useEffect } from 'react'

export const FilterContext = React.createContext()

export function FilterProvider(props) {
  const existingParams = new URLSearchParams(window.location.search);
  const [filters, setFilters] = useState({Display_in_Online_Directory__c: 'Yes'});
  const [filterListValues, setFilterListValues] = useState({});
  const [initialFiltersLoaded, setInitialFiltersLoaded] = useState(false);
  const [filterValuesFetched, setFilterValuesFetched] = useState(false);
  const MultiParams = ['Degree_Level_Credential_Offered__c', 'Regions__c'];

  const updateFilter = (param, selection) => {
    const previousState = { ...filters }

    if (previousState[param] !== selection) {
      setFilters({
        ...previousState,
        [param]: selection,
      })
    }
  }

  const currentQueryParams = () => {
    let { Display_in_Online_Directory__c, ...appliedFilters } = filters

    return Object.keys(appliedFilters).map((filterName) => {
      if(Array.isArray(appliedFilters[filterName])) {
        return appliedFilters[filterName].map((value) => {
          return `${filterName}=${encodeURI(value)}`
        }).join('&')
      } else {
        return `${filterName}=${encodeURI(appliedFilters[filterName])}`
      }
    }).join('&')
  }

  const removeFilter = (param) => {
    const {...newFilters} = filters
    delete newFilters[param]

    setFilters(newFilters)
  }

  const saveFilterListValues = (attr, values) => {
    filterListValues[attr] = values
    setFilterListValues(filterListValues)
  }

  useEffect(() => {
    var initialFilters = filters;

    [...existingParams.keys()].forEach((param) => {
      if(MultiParams.includes(param)) {
        initialFilters[param] = existingParams.getAll(param)
      } else if (param === 'minSalary' || param === 'maxSalary') {
        initialFilters[param] = parseInt(existingParams.get(param))
      } else {
        initialFilters[param] = existingParams.get(param)
      }
    })

    setInitialFiltersLoaded(true)
    setFilters(initialFilters)
  }, [])

  return (
    <FilterContext.Provider
      value={
        {
          filters,
          updateFilter,
          removeFilter,
          initialFiltersLoaded,
          currentQueryParams,
          saveFilterListValues,
          filterListValues,
          filterValuesFetched,
          setFilterValuesFetched
        }
      }
    >
      {props.children}
    </FilterContext.Provider>
  )
}

export const FilterConsumer = FilterContext.Consumer
