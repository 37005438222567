import React from 'react'
import { ButtonBase, makeStyles, useTheme } from '@material-ui/core';
import AuthApi from '../../auth/AuthApi';
import { Nav } from './Nav'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  navBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  navLogo: {
    height: '60px'
  }
}));

export function AuthenticatedNav() {
  function handleSignOut() {
    new AuthApi().signOut().then(() => {
      window.location =  "/sign_in";
    });
  };

  return (
    <Nav>
      <ButtonBase color="inherit" onClick={handleSignOut} data-role='sign-out-button'>Sign Out</ButtonBase>
    </Nav>
  )
}
